export default {
  install: (app) => {
    app.config.globalProperties.$helpers = {
      debounce: (fn, delay) => {
        // https://stackoverflow.com/questions/42199956/how-to-implement-debounce-in-vue2
        let timeoutID = null;
        return function () {
          clearTimeout(timeoutID);
          const args = arguments;
          const that = this;
          timeoutID = setTimeout(function () {
            fn.apply(that, args);
          }, delay);
        };
      },
      inDict: (key, dict) => {
        for (let field in dict) {
          if (field === key) {
            return true;
          }
        }
        return false;
      },
      returnCurrency: (value) => {
        if (value === null || value === "") return null;
        return new Intl.NumberFormat("sv-SE", {
          style: "currency",
          currency: "SEK",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value);
      },
    };
  },
};
