<template>
  <div class="box">
    <div class="boxOne">
      <br />

      <div class="row" id="'LoginForm">
        <legend class="col-md-6" id="ChangePassword">Välj nytt lösenord</legend>
        <div class="col-md-3"></div>
        <div class="col-md-6" id="PasswordBox">
          <form @submit.prevent="submitForm" id="login">
            <input
              type="password"
              onfocus="this.type = 'text'"
              onblur="this.type = 'password'"
              class="form-control"
              id="newpassword"
              placeholder="Nytt lösenord"
              v-model="data.new_password"
            />
            <input
              type="password"
              onfocus="this.type = 'text'"
              onblur="this.type = 'password'"
              class="form-control"
              id="confirmnewpassword"
              placeholder="Bekräfta nytt lösenord"
              v-model="data.re_new_password"
            />
            <button
              type="submit"
              :disabled="!allInputs"
              class="btn btn-primary"
              id="förstaSidan"
              data-target="#exampleModal"
              data-toggle="modal"
            >
              Bekräfta
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { axiosBase } from "@/axios-api";

export default {
  name: "ResetPasswordConfirm.vue",
  data() {
    return {
      data: {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
        new_password: "",
        re_new_password: "",
      },
      showPassword: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    allInputs: function () {
      return !!(this.data.new_password && this.data.re_new_password);
    },
  },
  methods: {
    checkInput() {
      return this.data.new_password === this.data.re_new_password;
    },
    async submitForm() {
      if (!this.checkInput()) {
        // Check that the two password fields match
        this.toast.error("Lösenorden är inte samma");
      } else {
        await axiosBase
          .post("auth/users/reset_password_confirm/", this.data)
          .then(async () => {
            this.toast.success("Lösenordet ändrat");
            await this.$router.push({ name: "Login" });
          })
          .catch((error) => {
            if (error.response) {
              // Request made and server responded
              let message = "";
              for (const property in error.response.data) {
                error.response.data[property].forEach(function (item) {
                  message += item;
                });
              }
              this.toast.error(message);
            } else if (error.request) {
              // The request was made but no response was received
              this.toast.error("Something went wrong");
            }
          });
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .box {
    margin-left: 450px;
    width: 50%;
    margin-top: 20px;
  }
}
@media (min-width: 1400px) {
  .box {
    margin-left: 40%;
    width: 35%;
    margin-top: 100px;
  }
}

button#förstaSidan.btn.btn-primary {
  margin-left: 2px !important;
}

#login {
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 9px 1px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#login {
  padding-left: 20px;
}

#PasswordBox {
  padding-left: 0;
}
</style>
