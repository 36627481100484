<template>
  <div class="dashboard">
    <Sidebar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";

export default {
  name: "sidebar",
  components: {
    Sidebar,
  },
};
</script>

<style>
.dashboard {
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.content {
  background-color: white;
  border-radius: 20px;
  margin: 6px 6px 6px 0;
}
#inlineFormCustomSelect {
  width: 100% !important;
}
th,
td {
  padding: 0;
}

.table > :not(:last-child) > :last-child > * {
  padding: 0.5rem !important;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
</style>
