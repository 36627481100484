<template>
  <div class="box">
    <div class="boxOne">
      <br />

      <div class="row" id="'LoginForm">
        <legend class="col-md-6" id="ChangePassword">Ändra lösenord</legend>
        <div class="col-md-3"></div>
        <div class="col-md-6" id="PasswordBox">
          <form @submit.prevent="submitForm" id="login">
            <div class="mb-3">
              <input
                class="form-control"
                onfocus="this.type = 'text'"
                onblur="this.type = 'password'"
                placeholder="Nuvarande lösenord"
                v-model="pass.current_password"
              />
            </div>

            <input
              class="form-control"
              onfocus="this.type = 'text'"
              onblur="this.type = 'password'"
              id="newpassword"
              placeholder="Nytt lösenord"
              v-model="pass.new_password"
            />
            <input
              class="form-control"
              onfocus="this.type = 'text'"
              onblur="this.type = 'password'"
              id="confirmnewpassword"
              placeholder="Bekräfta nytt lösenord"
              v-model="re_new_password"
            />
            <button
              type="submit"
              :disabled="!allInputs"
              class="btn btn-primary"
              id="förstaSidan"
              data-target="#exampleModal"
              data-toggle="modal"
            >
              Ändra
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { axiosBase } from "@/axios-api";

export default {
  name: "Password.vue",
  data() {
    return {
      user: this.$store.getters.userDetail,
      pass: { current_password: "", new_password: "" },
      re_new_password: "",
      showPassword: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    allInputs: function () {
      return !!(
        this.pass.current_password &&
        this.pass.new_password &&
        this.re_new_password
      );
    },
  },
  methods: {
    checkInput() {
      return this.pass.new_password === this.re_new_password;
    },
    async submitForm() {
      if (!this.checkInput()) {
        // Check that the two password fields match
        this.toast.error("Lösenorden är inte samma");
      } else {
        const userTest = {
          username: this.user.username,
          password: this.current_password,
        };
        await this.$store.dispatch("login", userTest);
        if (this.$store.getters.isLoggedIn) {
          // Check that current password is accurate
          await axiosBase
            .post("auth/users/set_password/", this.pass)
            .then(async () => {
              this.toast.success("Lösenord ändrat för " + this.user.username);
              await this.$router.push({ path: "/Main" });
            })
            .catch((error) => {
              if (error.response) {
                // Request made and server responded
                let message = "";
                for (const property in error.response.data) {
                  error.response.data[property].forEach(function (item) {
                    message += item;
                  });
                }
                this.toast.error(message);
              } else if (error.request) {
                // The request was made but no response was received
                this.toast.error("Something went wrong");
              }
            });
        } else {
          // current password is wrong
          this.toast.error("Nuvarande lösenord är fel");
        }
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .box {
    margin-left: 450px;
    width: 50%;
    margin-top: 20px;
  }
}
@media (min-width: 1400px) {
  .box {
    margin-left: 40%;
    width: 35%;
    margin-top: 100px;
  }
}

button#förstaSidan.btn.btn-primary {
  margin-left: 2px !important;
}

#login {
  box-shadow: rgb(0 0 0 / 6%) 1px 1px 9px 1px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

#login {
  padding-left: 20px;
}

#PasswordBox {
  padding-left: 0;
}
</style>
